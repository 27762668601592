import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import "./video-wrapper.css"

const VideoWrapper = ({
  exitOption,
  controls,
  src,
  open,
  onVideoEnd,
  handleClose
}) => {
  const videoRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  function endVideo() {
    onVideoEnd && onVideoEnd()
  }

  function close() {
    setIsOpen(false)
    document.body.style.overflowY = "auto"
    document.body.style.padding = "0"
  }

  useEffect(() => {
    if (!isOpen) {
      videoRef.current.pause()
      handleClose && handleClose()
    }
  }, [isOpen])

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden"
      document.body.style.padding = "17px"
    }
    setIsOpen(open)
  }, [open])

  return (
    <div className={`page-video-wrapper ${open ? "active" : ""} `}>
      {exitOption ? (
        <div className="close-icon" onClick={() => close()}>
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/close-icon.png"
            }
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      <video
        ref={videoRef}
        preload="none"
        width="100%"
        controls={controls || false}
        playsInline={true}
        onEnded={() => endVideo()}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoWrapper
