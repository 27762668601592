import React from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import Home from "../pages/Home"
import Marketing from "../pages/Tradinional Marketing"
import Sales from "../pages/Sales"
import World3D from "../pages/3DWorld"
// import About from "../pages/About";
import Investitor from "../pages/Investitor"
import Error from "../pages/Error"
import { AnimatePresence } from "framer-motion"
import AppWrapper from "./AppWrapper/AppWrapper"

function AnimatedRoutes() {
  const location = useLocation()

  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path="/"
        element={
          <AppWrapper id={location.pathname}>
            <Home />
          </AppWrapper>
        }
      ></Route>

      <Route
        path="/marketing"
        element={
          <AppWrapper id={location.pathname}>
            <Marketing />
          </AppWrapper>
        }
      />
      <Route
        path="/investor"
        element={
          <AppWrapper id={location.pathname}>
            <Investitor />
          </AppWrapper>
        }
      />
      <Route
        path="/3d-world"
        element={
          <AppWrapper id={location.pathname}>
            <World3D />
          </AppWrapper>
        }
      />
      <Route
        path="/sales"
        element={
          <AppWrapper id={location.pathname}>
            <Sales />
          </AppWrapper>
        }
      />
      <Route
        path="*"
        element={
          <AppWrapper id={location.pathname}>
            <Error />
          </AppWrapper>
        }
      />
    </Routes>
  )
}

export default AnimatedRoutes
