import React, { useState } from "react"
import Navmenu from "../Navmenu/Navmenu"
import SocialLinks from "../SocialLinks/SocialLinks"
import "./header.css"

function Header({ state }) {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <header
        className={`header ${state && !visible ? state : ""} ${
          visible ? "fixed" : ""
        }`}
        style={{
          background: "transparent"
        }}
      >
        <button
          className={`burger-icon ${visible ? "active" : ""}`}
          onClick={() => {
            setVisible(!visible)
          }}
        >
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
        </button>
        <SocialLinks />
      </header>
      <Navmenu isVisible={visible} handleClick={() => setVisible(false)} />
    </>
  )
}

export default Header
