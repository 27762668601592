import React, { Suspense, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import Model from "../../components/Model"
import About from "../About"

import { particlesInit, yellowDots } from "../../assets/particlesConfig"

import Particles from "react-tsparticles"
import { Canvas } from "@react-three/fiber"
import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import "./home.css"
import { useRef } from "react"

function Home() {
  const ref = useRef(null)
  const location = useLocation()
  const [pageOffset, setPageOffset] = useState(0)

  useEffect(() => {
    if (location.hash == "#about") {
      document.getElementById("about").scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [location])

  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"]
  })

  let scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })
  let opacity = useTransform(scrollYProgress, [0, 1], [1, 0])

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setPageOffset(latest * 100)
    })
  }, [])

  return (
    <>
      <motion.div
        transition={{
          type: "spring"
        }}
        animate={{
          y: pageOffset + "%"
        }}
        style={{ opacity }}
        ref={ref}
      >
        <div className="home">
          <Particles
            id="home-particles"
            className="particles-here"
            init={particlesInit}
            options={yellowDots}
          />

          <div className="home__menu">
            <div className="center">
              <Canvas>
                <directionalLight position={[1, 5, 5]} />
                <Suspense fallback={null}>
                  <Model />
                </Suspense>
              </Canvas>
            </div>
            <Link to="/marketing" className="home-menu-item deg225">
              <h2>FULL STACK</h2>
            </Link>
            <Link to="/investor" className="home-menu-item deg315">
              <h2>INVESTOR</h2>
            </Link>
            <Link to="/3d-world" className="home-menu-item deg180">
              <h2>3D WORLD</h2>
            </Link>
            <Link to="/sales" className="home-menu-item deg10">
              <h2>HIRE SALES PERSON</h2>
            </Link>
            <a
              href="/#about"
              id="about-home-menu-item"
              className="home-menu-item deg90"
            >
              <h2>ABOUT US</h2>
              {/* <Link to="/about" className="home-menu-item deg90"> */}
              {/* <h2>ABOUT US</h2> */}
              <motion.div
                animate={{
                  translateY: ["0", "5px", "-5px"],
                  opacity: [0.8, 1, 0.9]
                }}
                transition={{
                  duration: 1,
                  ease: "linear",
                  repeat: Infinity
                }}
                style={{ width: "15%" }}
              >
                <img
                  src={
                    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/arrow-down.png"
                  }
                  alt="arrow-down"
                />
              </motion.div>

              {/* </Link> */}
            </a>
          </div>
        </div>
      </motion.div>
      <motion.div
        style={{
          scaleX: scaleX,
          // height: "0px",
          backgroundColor: "var(--clr-primary)",
          height: "3px",
          right: "0",
          left: "0",
          position: "absolute",
          bottom: 0,
          zIndex: 5
        }}
      ></motion.div>
      <motion.div>
        <About />
      </motion.div>
    </>
  )
}

export default Home
