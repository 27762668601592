import React from "react"

import Marker from "../../components/Marker/Marker"
import Footer from "../../components/Footer/Footer"
import Button from "../../components/Button/Button"
import { useMobileContext } from "../../context/MobileContext"

import "./traditional-marketing.css"
import { motion } from "framer-motion"
import Carousel from "react-multi-carousel"
import Xarrow from "react-xarrows"
import { usePreloadContext } from "../../context/PreloadContext"
import OutlinedTitle from "../../components/OutlinedTitle/OutlinedTitle"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

function Marketing() {
  const isMobile = useMobileContext()
  const { images } = usePreloadContext()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  }

  return (
    <div
      className="marketing"
      style={{ backgroundImage: `url(${images.marketingBg})` }}
    >
      <section>
        <div className="container">
          <div className="flex-split">
            <Marker index={1} inner={images.marker1} id="elem1" />
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              whileInView={{ opacity: 1, x: "0" }}
              transition={{ ease: "easeIn", duration: 0.7 }}
            >
              <div className="info ">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  WEBSITE
                </OutlinedTitle>

                <p>
                  There is no more expensive thing than a cheaply made website.
                  Only a high-quality and professionally designed website will
                  bring you profit, attract users and allow you to convert them
                  into customers. Vice versa, a low quality website will only
                  get you a waste of money.
                  <br></br>
                  <span style={{ marginTop: "10px" }}>
                    If you agree with that.
                  </span>
                </p>
                <Button
                  className="primary-btn"
                  link="https://calendly.com/problem-solver-agency/website-discovery"
                >
                  BOOK A CALL
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="flex-split | column-reverse">
            <motion.div
              initial={{ opacity: 0, x: "-100%" }}
              whileInView={{ opacity: 1, x: "0" }}
              transition={{ ease: "easeIn", duration: 0.7 }}
            >
              <div className="info">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  2D ANIMATION
                </OutlinedTitle>

                <p>
                  If they say a picture is worth a thousand words, and a video
                  is made out of thousands of pictures, what do you think it's
                  worth is? Just imagine how many words a video can replace! Our
                  job is to present your long texts, industry terminology and
                  innovative products/services in a concise, exciting and
                  understandable way that will transform the visitor into a
                  customer.
                </p>
                <div
                  className="carousel"
                  style={{
                    position: "relative"
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    draggable={true}
                    arrows={true}
                    infinite={false}
                    itemClass="item"
                  >
                    <LiteYouTubeEmbed
                      id="ZDrX3zDxfzg"
                      title="Explainer for DTI"
                    />
                    <LiteYouTubeEmbed
                      id="mKcQzS11yGw"
                      title="About Arbitrage"
                    />
                    <LiteYouTubeEmbed id="E4cbbWA4fCc" title="Shard Labs 2" />
                  </Carousel>
                </div>
                <Button
                  className="primary-btn"
                  link="https://zeeg.me/bogmeeting/hellomeeting"
                >
                  BOOK A CALL
                </Button>
              </div>
            </motion.div>
            <Marker inner={images.giphy} spec="marker__gif" id="elem2" />
          </div>
        </div>
      </section>
      {!isMobile && (
        <Xarrow
          start={"elem1"}
          end={"elem2"}
          curveness={0}
          _cpx1Offset={450}
          _cpx2Offset={20}
          color="white"
          startAnchor="right"
          endAnchor="top"
          showHead={false}
          dashness={true}
        />
      )}
      <section>
        <div className="container">
          <div className="flex-split">
            <Marker inner={images.marker2} id="elem3" />
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              whileInView={{ opacity: 1, x: "0" }}
              transition={{ ease: "easeIn", duration: 0.7 }}
            >
              <div className="info">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  PAID ADVERTISEMENTS
                </OutlinedTitle>

                <p>
                  Facebook, YouTube, Google, TikTok, LinkedIn, Instagram - your
                  business can be everywhere! We can help you achieve positive
                  results on every platform. At the right time, in the right
                  place and with the right content, convert an ordinary user
                  into your client!
                </p>
                <Button
                  className="primary-btn"
                  link="https://calendly.com/problem-solver-agency/paid-ads-discovery-call"
                >
                  BOOK A CALL
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {!isMobile && (
        <Xarrow
          start={"elem2"}
          end={"elem3"}
          curveness={0}
          _cpx1Offset={-450}
          _cpx2Offset={-20}
          color="white"
          showHead={false}
          startAnchor="left"
          endAnchor="top"
          dashness={true}
        />
      )}
      <section>
        <div className="container">
          <div className="flex-split | column-reverse">
            <motion.div
              initial={{ opacity: 0, x: "-100%" }}
              whileInView={{ opacity: 1, x: "0" }}
              transition={{ ease: "easeIn", duration: 0.7 }}
            >
              <div className="info">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  SOCIAL NETWORKS
                </OutlinedTitle>

                <p>
                  Social networks contribute to the branding of the company and
                  allow you to reach a large number of users, your potential
                  customers. Professional management of social networks is
                  crucial because they represent a place where users can
                  directly communicate with you and form an image of you and the
                  overall picture you send about your business through content.
                </p>
                <Button
                  className="primary-btn"
                  link="https://calendly.com/problem-solver-agency/social-media-discovery-call"
                >
                  BOOK A CALL
                </Button>
              </div>
            </motion.div>
            <Marker inner={images.marker3} id="elem4" />
          </div>
        </div>
      </section>
      {!isMobile && (
        <Xarrow
          start={"elem3"}
          end={"elem4"}
          curveness={0}
          _cpx1Offset={450}
          _cpx2Offset={20}
          color="white"
          showHead={false}
          startAnchor="right"
          endAnchor="top"
          dashness={true}
        />
      )}
      <section>
        <div className="container">
          <div className="flex-split">
            <Marker inner={images.marker4} id="elem5" />
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              whileInView={{ opacity: 1, x: "0" }}
              transition={{ ease: "easeIn", duration: 0.7 }}
            >
              <div className="info">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  (RE)BRANDING
                </OutlinedTitle>
                <p>
                  Brand identity is a collection of elements that serve to
                  represent and differentiate you from thecompetitors. Having a
                  powerfuland unique branding can help youattract clients and
                  help them forma great picture of your company. Our team will
                  create all sorts ofvisuals: full brand identity, promotional
                  material, publications, infographics, icons, e-learnings and
                  templates – all in all, whateveryou can think of!
                </p>
                <Button
                  className="primary-btn"
                  link="https://calendly.com/problem-solver-agency/re-branding-discovery-call"
                >
                  BOOK A CALL
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {!isMobile && (
        <Xarrow
          start={"elem4"}
          end={"elem5"}
          curveness={0}
          _cpx1Offset={-450}
          _cpx2Offset={-20}
          color="white"
          showHead={false}
          startAnchor="left"
          endAnchor="top"
          dashness={true}
        />
      )}

      <section className="marketing__finish">
        <div className="container">
          {/* <div className="last-stop"> */}
          <div className="last-stop">
            <div className="marker">
              <motion.div
                initial={{ scale: 0 }}
                whileInView={{ scale: [0, 1.2, 1] }}
                transition={{
                  type: "spring",
                  bounce: 2,
                  duration: 1,
                  delayChildren: 0.3,
                  staggerChildren: 0.05
                }}
              >
                <img
                  src={
                    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/flag.png"
                  }
                  alt="Final flag"
                />
              </motion.div>
              <div className="marker__dot" id="elem6"></div>
            </div>
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: [0, 1.2, 1] }}
              transition={{
                type: "spring",
                bounce: 2,
                duration: 1,
                delayChildren: 0.3,
                staggerChildren: 0.05
              }}
            >
              <div className="info text-wrapper">
                <OutlinedTitle tag="h2" color={"var(--clr-accent)"}>
                  FULL SERVICE
                </OutlinedTitle>
                <p>
                  The first impression customers get of you is based on your
                  marketing. Clients buy the quality of your marketing and how
                  you advertise, and only then what you sell. Why would you
                  allow that when you can get all of the above from one agency?
                </p>
              </div>
            </motion.div>
            {/* </div> */}
          </div>
        </div>
      </section>
      {!isMobile && (
        <Xarrow
          start={"elem5"}
          end={"elem6"}
          curveness={0}
          _cpx1Offset={300}
          _cpx2Offset={0}
          color="white"
          showHead={false}
          dashness={true}
          startAnchor="right"
          endAnchor="top"
        />
      )}

      <Footer link="https://calendly.com/problem-solver-agency/full-service-discovery-call" />
    </div>
  )
}

export default Marketing
