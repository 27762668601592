import React from "react"
import SocialLinks from "../SocialLinks/SocialLinks"
import "./footer.css"
import NavInfo from "../NavInfo/NavInfo"
import { motion } from "framer-motion"

function Footer({ link }) {
  return (
    <footer>
      <div className="wrapper">
        <img
          src={
            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/footer-logo.png"
          }
          alt="logo"
        />
      </div>
      <div className="lower">
        <p>Problem Solver 2022</p>
      </div>
      <motion.button
        whileHover={{ scale: [0.8, 1.2, 1] }}
        transition={{ duration: 1, ease: "linear", repeat: Infinity }}
      >
        <a href={link} target="_blank">
          {" "}
          BOOK A<br></br> CALL{" "}
        </a>
      </motion.button>
      <div className="info-social">
        <NavInfo />
        <SocialLinks />
      </div>
    </footer>
  )
}

export default Footer
