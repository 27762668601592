import React, { useState, useEffect } from "react"
import Header from "../Header/Header"
import BackToTopBtn from "../BackToTopBtn/BackToTopBtn"
import "./app-wrapper.css"
import { MobileProvider } from "../../context/MobileContext"
import { useLocation } from "react-router-dom"

const AppWrapper = ({ children, id }) => {
  const [isBtnShown, setIsBtnShown] = useState(false)
  const location = useLocation()
  const [headerState, setHeaderState] = useState("active")

  function handleScroll(e) {
    if (window.scrollY >= 800) {
      setIsBtnShown(true)
    } else {
      setIsBtnShown(false)
    }
    if (window.scrollY >= 40) {
      setHeaderState("revert")
      if (window.scrollY >= 100) {
        setHeaderState("revert hide")
      }
    } else if (window.scrollY == 0) {
      setHeaderState("active")
    }
  }

  const scrollToTop = () => {
    if (location.hash != "about") {
      console.log("scroll")
      window.scrollTo({ top: 0 })
    }
  }

  useEffect(() => {
    scrollToTop()
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <MobileProvider>
      <div style={{ position: "relative", height: "100vh" }}>
        <Header state={headerState} />
        <BackToTopBtn show={isBtnShown} handleClick={() => scrollToTop()} />
        {children}
      </div>
    </MobileProvider>
  )
}

export default AppWrapper
