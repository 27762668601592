import React, { createContext, useContext, useEffect, useState } from "react"

const MobileContext = createContext()

export const useMobileContext = () => {
  return useContext(MobileContext)
}

export const MobileProvider = ({ children }) => {
  const [size, setSize] = useState(window.innerWidth)
  const [mobile, setMobile] = useState()

  useEffect(() => {
    setSize(window.innerWidth)
    window.addEventListener("resize", reSize, { passive: true })
    return () => {
      window.removeEventListener("resize", reSize)
    }
  }, [])

  const reSize = () => {
    setSize(window.innerWidth)
  }

  useEffect(() => {
    // console.log(size);
    if (size > 959 && mobile !== false) {
      setMobile(false)
    } else if (size <= 959 && mobile !== true) {
      setMobile(true)
    }
  }, [size])

  return (
    <MobileContext.Provider value={mobile}>{children}</MobileContext.Provider>
  )
}
