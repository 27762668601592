import React from "react"
import "./progress-page.css"

const ProgressPage = ({ loading }) => {
  return (
    <div className={`progress-page ${loading ? "show" : ""} `}>
      <div className="progress">
        <div className="progress-value"></div>
      </div>
    </div>
  )
}

export default ProgressPage
