import React from "react";
import "./button.css";

const Button = ({ style, link, className, children }) => {
  return (
    <button className={`${className}`} style={style} id="fourth">
      <a href={link} target="_blank">
        {children}
      </a>
    </button>
  );
};

export default Button;
