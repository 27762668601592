import React, { Suspense, useState } from "react"
import OutlinedTitle from "../../components/OutlinedTitle/OutlinedTitle"

import { motion } from "framer-motion"
import "./3d-world.css"
import { useMobileContext } from "../../context/MobileContext"
import { blackStars } from "../../assets/blackStars"
import { particlesInit } from "../../assets/particlesConfig"
import Particles from "react-tsparticles"
import Footer from "../../components/Footer/Footer"
import Carousel from "react-multi-carousel"
import { useRef } from "react"
import { Canvas } from "@react-three/fiber"
import Graph from "./models/Graph"

const Index = () => {
  const isMobile = useMobileContext()
  const [isFirstHovered, setIsFirstHovered] = useState(false)
  const [isSecondHovered, setIsSecondHovered] = useState(false)
  const [isThirdHovered, setIsThirdHovered] = useState(false)
  const video3DRef = useRef()
  const headerVariants = {
    rest: {
      y: 200,
      scale: 1.5,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 200,
      scale: 1.5,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [200, 60, 200],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  const textVariants = {
    rest: {
      y: 200,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 200,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [200, 60, 200],
      opacity: [0, 1, 0],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <>
      <div className="td-world">
        <section className="intro" style={{ marginBottom: "10vh" }}>
          <Particles
            id="about-particles"
            className="particles-here"
            init={particlesInit}
            options={blackStars}
          />

          <div className="container">
            <div className="intro__wrapper">
              <div className="intro__header">
                <OutlinedTitle>3D WORLD</OutlinedTitle>

                <h4 className="text-wrapper" style={{ fontWeight: 700 }}>
                  <span className="primary">Coming soon.</span>
                  <br></br>
                  3D World is the section of Problem Solver that deals with
                  crafting:
                </h4>
              </div>
              <div className="about-us__body">
                <motion.div
                  className="about-us__card"
                  initial={isMobile ? "restMobile" : "rest"}
                  whileHover="hover"
                  whileInView={isMobile ? "" : "inView"}
                  onClick={() => setIsFirstHovered(!isFirstHovered)}
                  animate={
                    isMobile
                      ? isFirstHovered
                        ? "hover"
                        : "restMobile"
                      : "rest"
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflow: "hidden",
                      flex: 1
                    }}
                  >
                    <motion.h5
                      className="primary"
                      style={{ letterSpacing: "0.2rem", paddingTop: "30px" }}
                      variants={headerVariants}
                    >
                      3D WEBSITES
                    </motion.h5>
                    <motion.p
                      variants={textVariants}
                      style={{ textAlign: "center" }}
                    >
                      <br></br>
                      <br></br> In these modern times, when a good website is
                      mandatory for a successful business, a classic site is not
                      enough anymore to achieve the WOW effect. If you want to
                      instantly differentiate your company from the competition
                      and attract customers with innovative and modern
                      solutions, then a 3D site is a suitable option.
                    </motion.p>
                  </div>
                </motion.div>
                <motion.div
                  className="about-us__card"
                  initial={isMobile ? "restMobile" : "rest"}
                  whileHover="hover"
                  whileInView={isMobile ? "" : "inView"}
                  onClick={() => setIsSecondHovered(!isSecondHovered)}
                  animate={
                    isMobile
                      ? isSecondHovered
                        ? "hover"
                        : "restMobile"
                      : "rest"
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflow: "hidden",
                      flex: 1
                    }}
                  >
                    <motion.h5
                      className="primary"
                      style={{ letterSpacing: "0.2rem", paddingTop: "30px" }}
                      variants={headerVariants}
                    >
                      3D VIDEOS
                    </motion.h5>

                    <motion.p
                      variants={textVariants}
                      style={{ textAlign: "center" }}
                    >
                      <br></br>
                      <br></br> Adding 3D Visualisation improves conversions by
                      94%, on average. 44% of shoppers are more likely to add an
                      item to cart after interacting with 3D, 60% of shoppers
                      are more likely to buy a product after trying it out in 3D
                      or Augmented Reality. Want to show your products in 3D
                      dimensions so that people can see them much better than in
                      a picture and thus increase their demand? Just tell us
                      what, and we'll turn it into 3D.
                    </motion.p>
                  </div>
                </motion.div>
                <motion.div
                  className="about-us__card"
                  initial={isMobile ? "restMobile" : "rest"}
                  whileHover="hover"
                  whileInView={isMobile ? "" : "inView"}
                  onClick={() => setIsThirdHovered(!isThirdHovered)}
                  animate={
                    isMobile
                      ? isThirdHovered
                        ? "hover"
                        : "restMobile"
                      : "rest"
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflow: "hidden",
                      flex: 1
                    }}
                  >
                    <motion.h5
                      className="primary"
                      style={{ letterSpacing: "0.2rem", paddingTop: "30px" }}
                      variants={headerVariants}
                    >
                      3D MODELS
                    </motion.h5>

                    <motion.p
                      variants={textVariants}
                      style={{ textAlign: "center" }}
                    >
                      <br></br>
                      <br></br> The best way to sell a product is to present it
                      in a 3D model. According to the data, you have a 7,7x
                      better chance to sell the item if you provide a 3D model.
                      This method is much more interesting, different and
                      psychologically compelling.
                    </motion.p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section className="under-construction">
          <div
            className="text-wrapper container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h4 className="intensified" style={{ textAlign: "center" }}>
              Final preparations are under way, and until then, you can take a
              look at some of our work:
            </h4>
            <motion.a
              href="#websites"
              animate={{
                translateY: ["0", "5px", "-5px"],
                opacity: [0.9, 1, 0.9]
              }}
              transition={{
                duration: 1,
                ease: "linear",
                repeat: Infinity
              }}
              style={{ width: "7%" }}
            >
              <img
                src={
                  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver@0.0.1/images/arrow-down.png"
                }
                alt="arrow-down"
              />
            </motion.a>
          </div>
          <video id="background-video" muted playsInline loop autoPlay>
            <source
              src={
                "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver@0.0.1/images/under-construction-background2.mp4"
              }
              type="video/mp4"
            />
            <source
              src={
                "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver@0.0.1/images/under-construction-background2.mp4"
              }
              type="video/ogg"
            />
          </video>
        </section>
        <section id="websites" className="work" style={{ marginTop: "10vh" }}>
          <div className="container">
            <div className="work-wrapper">
              <OutlinedTitle>3D WEBSITES</OutlinedTitle>

              <div className="examples-section">
                <div
                  className="examples-carousel "
                  style={{
                    position: "relative"
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    draggable={isMobile}
                    swipeable={isMobile}
                    // customTransition="all .5"
                    arrows
                    infinite={false}
                    itemClass="item"
                  >
                    <div className="website-example">
                      <a
                        target="_blank"
                        href="https://www.alteredstatemachine.xyz/"
                      >
                        {/* <video muted playsInline loop>
                          <source src={websiteExample1} type="video/mp4" />
                        </video> */}
                        <img
                          src={
                            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/website-example-1.png"
                          }
                          alt="https://www.alteredstatemachine.xyz/"
                        />
                      </a>
                    </div>
                    <div className="website-example">
                      <a target="_blank" href="https://pocketverse.net/">
                        {/* <video muted playsInline loop>
                          <source src={websiteExample2} type="video/mp4" />
                        </video> */}
                        <img
                          src={
                            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/website-example-2.png"
                          }
                          alt="https://pocketverse.net/"
                        />
                      </a>
                    </div>
                    <div className="website-example">
                      <a target="_blank" href="https://totem.itsoffbrand.com/">
                        {/* <video muted playsInline loop>
                          <source src={websiteExample3} type="video/mp4" />
                        </video> */}
                        <img
                          src={
                            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/website-example-3.png"
                          }
                          alt="https://totem.itsoffbrand.com/"
                        />
                      </a>
                    </div>
                    <div className="website-example">
                      <a target="_blank" href="https://dala.craftedbygc.com/">
                        {/* <video muted playsInline loop>
                          <source src={websiteExample4} type="video/mp4" />
                        </video> */}
                        <img
                          src={
                            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/website-example-4.png"
                          }
                          alt="https://dala.craftedbygc.com/"
                        />
                      </a>
                    </div>
                    <div className="website-example">
                      <a target="_blank" href="https://www.blueyard.com/">
                        {/* <video muted playsInline loop>
                          <source src={websiteExample5} type="video/mp4" />
                        </video> */}
                        <img
                          src={
                            "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/website-example-5.png"
                          }
                          alt="https://www.blueyard.com/"
                        />
                      </a>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="videos" className="work">
          <div className="container">
            <div className="work-wrapper">
              <OutlinedTitle>3D VIDEOS</OutlinedTitle>
              <div className="examples-section">
                <div className="video-3d">
                  <video
                    ref={video3DRef}
                    width="100%"
                    controls={true}
                    playsInline
                    preload="none"
                  >
                    <source
                      src={
                        "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/Solver_reel.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                  <img
                    src={
                      "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/Solver_reel-image.png"
                    }
                    className="video-thumbnail"
                    alt="3d-video"
                    style={{ opacity: 1 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="models" className="work">
          <div className="work-wrapper">
            <div className="container">
              <OutlinedTitle>3D MODELS</OutlinedTitle>
            </div>

            <div className="model-3d">
              <Canvas>
                <directionalLight position={[1, 5, 5]} />
                <Suspense fallback={null}>
                  <Graph />
                </Suspense>
              </Canvas>
            </div>
            <div className="model-3d" style={{ justifyContent: "center" }}>
              <img
                src="https://cdn.jsdelivr.net/gh/mrbeban/problem-solver@0.0.1/images/Soba0001.png"
                width={"100%"}
                style={{ maxWidth: "1500px" }}
                alt="3d room"
              />
            </div>
          </div>
        </section>
        <Footer link="https://calendly.com/problem-solver-agency/3d-world" />
      </div>
    </>
  )
}

export default Index
