import React from "react"
import { NavLink, Link, useLocation } from "react-router-dom"
// import rotatedLogo from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/rotated-logo.png"
// import mobileLogo from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/mobile-logo.png"
import SocialLinks from "../SocialLinks/SocialLinks"
import "./navmenu.css"
import NavInfo from "../NavInfo/NavInfo"
import { useEffect } from "react"

function Navmenu(props) {
  const location = useLocation()

  function handleNavigate(destination) {
    if (destination === "home") {
      window.scrollTo({ top: 0 })
    }
    props.handleClick()
  }

  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflowY = "hidden"
      document.body.style.paddingRight = "17px"
    } else {
      document.body.style.overflowY = "auto"
      document.body.style.paddingRight = "0"
    }
  }, [props.isVisible])

  return (
    <nav className={`navmenu ${props.isVisible ? "visible" : ""}`}>
      <div className="primary-navigation">
        <SocialLinks show={props.isVisible} />
        <ul className={`nav__list ${props.isVisible ? "visible" : ""}`}>
          <li>
            <NavLink
              end
              to="/"
              onClick={() => handleNavigate("home")}
              className={({ isActive }) =>
                `${location.hash === "#about" ? "" : isActive ? "active" : ""}`
              }
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink to="/marketing">FULL STACK</NavLink>
          </li>
          <li>
            <NavLink to="/investor">INVESTOR</NavLink>
          </li>
          <li>
            <NavLink to="/3d-world">3D WORLD</NavLink>
          </li>
          <li>
            <NavLink to="/sales">HIRE SALES PERSON</NavLink>
          </li>
          <li>
            {/* <Link className="" to="/#about">ABOUT US</Link> */}
            <Link
              to="/#about"
              onClick={() => handleNavigate("about")}
              className={`${location.hash === "#about" ? "active" : ""}`}
            >
              ABOUT US
            </Link>
          </li>
        </ul>
        <div className="nav__mobile-logo">
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/mobile-logo.png"
            }
            alt="logoProblemSolver"
          />
        </div>
        <div className={`nav__logo ${props.isVisible ? "visible" : ""}`}>
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/rotated-logo.png"
            }
            alt="logoProblemSolver"
          />
        </div>
        <NavInfo />
      </div>
    </nav>
  )
}

export default Navmenu
