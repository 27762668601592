import React, { useEffect, useRef } from "react"
import { useMobileContext } from "../../context/MobileContext"
import "./outlined-title.css"
import { useScroll, motion } from "framer-motion"
import { useState } from "react"

const OutlinedTitle = ({ tag, children, color, size }) => {
  const isMobile = useMobileContext()
  const titleRef = useRef(null)
  const [titlePosition, setTitlePosition] = useState(0)
  const { scrollYProgress } = useScroll({
    target: titleRef,
    offset: ["end end", "start start"]
  })

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setTitlePosition(40 - latest * 50)
    })
  }, [])

  const Tag = tag || "h1"

  return (
    <div ref={titleRef}>
      <Tag className="outlined-title" style={size && { fontSize: `${size}px` }}>
        {children}
        <motion.span
          className="outlined-title-shadow"
          animate={{
            top: titlePosition + "px",
            x: "-50%"
          }}
          transition={{ type: "spring" }}
          style={{
            WebkitTextStroke: color && `${isMobile ? "2.5px" : "5px"} ${color}`,
            fontSize: size ? `${size}px` : "inherit"
            // top: titlePosition + "%"
            // transition: "all linear 100ms"
          }}
        >
          {children}
        </motion.span>
      </Tag>
    </div>
  )
}

export default OutlinedTitle
