import React from "react"
import { useRef } from "react"
import { useThree, useFrame } from "@react-three/fiber"
import { useFBX } from "@react-three/drei"
import { useEffect } from "react"

function Graph() {
  const fbx = useFBX(
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/models/Nvidia3090.fbx"
  )

  const ref = useRef()

  const { size } = useThree()
  let position = size.width < 700 ? [0, 0.2, 0] : [0, -1.1, 0]
  //   let scale = size.width < 1088 ? 0.005 : size.width < 1460 ? 0.01 : 0.02
  let scale = size.width < 700 ? 0.005 : 0.009

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime()
    ref.current.rotation.y = a
    // ref.current.lookAt(mouse.x, mouse.y, camera.position.z)
  })

  return <primitive object={fbx} scale={scale} ref={ref} position={position} /> //put scale to 0.02
}

export default Graph
