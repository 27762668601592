import React from "react"

import "./social-links.css"

function SocialLinks(props) {
  return (
    <ul className="social-links">
      <li>
        <a
          href="https://www.instagram.com/_problemsolveragency_/"
          target="_blank"
        >
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/instagram-icon.png"
            }
            alt="instagram-icon"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/problem-solver-2022/"
          target="_blank"
        >
          <img
            id="linkedin"
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/linkedin-logo.png"
            }
            alt="linkedin-icon"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/profile.php?id=100086796339327"
          target="_blank"
        >
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/facebook-icon.png"
            }
            alt="facebook-icon"
          />
        </a>
      </li>
      <li>
        <a
          href="https://youtube.com/channel/UC5MAuHNWa1-8X1UyChCmsxA"
          target="_blank"
        >
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/youtube-icon.png"
            }
            alt="youtube-icon"
          />
        </a>
      </li>
      <li>
        <a href="https://www.tiktok.com/@problemsolveragency" target="_blank">
          <img
            src={
              "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/tik-tok-icon.png"
            }
            alt="tik-tok-icon"
          />
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks
