import React from "react"

import { motion } from "framer-motion"
import "./marker.css"
import { usePreloadContext } from "../../context/PreloadContext"

function Marker(props) {
  const { images } = usePreloadContext()

  return (
    <motion.div className="marker">
      <motion.div
        className="marker-wrapper"
        initial={{ y: 0, scale: 0 }}
        whileInView={{ y: 0, scale: 1 }}
        transition={{
          type: "spring",
          bounce: 0,
          duration: 1.5,
          delayChildren: 0.3,
          staggerChildren: 0.05
        }}
      >
        <img className="marker__outer" src={images.marker} alt="" />
        <img
          className={`marker__inner ${props.spec} `}
          src={props.inner}
          alt=""
        />
      </motion.div>
      <div className="marker__dot" id={props.id}></div>
    </motion.div>
  )
}

export default Marker
