import { yellowDots } from "./yellowDots"
import { purpleDots } from "./purpleDots"
import { whiteDots } from "./whiteDots"
import { whiteLines } from "./whiteLines"
import { yellowLines } from "./yellowLines"
import { loadFull } from "tsparticles"

export { yellowLines, purpleDots, whiteDots, yellowDots, whiteLines }

export const particlesInit = async (main) => {
  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  // starting from v2 you can add only the features you need reducing the bundle size
  await loadFull(main)
}
