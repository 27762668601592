import React, { useState } from "react"
import "./sales.css"

import Particles from "react-tsparticles"

import { particlesInit, purpleDots } from "../../assets/particlesConfig"
import Footer from "../../components/Footer/Footer"
import SalesCards from "../../components/SalesCards/SalesCards"
import { useMobileContext } from "../../context/MobileContext"
import OutlinedTitle from "../../components/OutlinedTitle/OutlinedTitle"

import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import SalesIntro from "./components/SalesIntro"
import Button from "../../components/Button/Button"
import { usePreloadContext } from "../../context/PreloadContext"
import { useEffect, useRef } from "react"

const salesSolutionExample1 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/solution-example-1.png"
const salesSolutionExample2 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/solution-example-2.png"
const salesSolutionExample3 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/solution-example-3.png"
const salesProcess1 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess1.png"
const salesProcess2 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess2.png"
const salesProcess3 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess3.png"
const salesProcess4 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess4.png"
const salesProcess5 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess5.png"
const salesProcess6 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess6.png"
const salesProcess7 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcess7.png"
const salesProcessVertical1 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical1.png"
const salesProcessVertical2 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical2.png"
const salesProcessVertical3 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical3.png"
const salesProcessVertical4 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical4.png"
const salesProcessVertical5 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical5.png"
const salesProcessVertical6 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical6.png"
const salesProcessVertical7 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/salesProcessVertical7.png"
const salesEnd1 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/end-card-1.jpg"
const salesEnd2 =
  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/end-card-2.jpg"

gsap.registerPlugin(ScrollTrigger)

function Sales() {
  const isMobile = useMobileContext()
  const [pageOffset, setPageOffset] = useState(0)

  const { images } = usePreloadContext()
  const ref = useRef(null)
  const whatsNextRef = useRef(null)

  const paralaxRef = useRef(null)

  let { scrollYProgress } = useScroll({
    target: paralaxRef,
    offset: ["end end", "end start"]
  })

  let scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })
  let opacity = useTransform(scrollYProgress, [0, 1], [1, 0])

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setPageOffset(latest * 100)
    })
  }, [])

  useEffect(() => {
    let ctx = gsap.context(() => {
      isMobile
        ? gsap.fromTo(
            ".sales-card:not(:first-child)",
            {
              x: 200,
              opacity: 0
            },
            {
              x: 0,
              opacity: 1,
              stagger: 0.5,
              scrollTrigger: {
                trigger: ref.current,
                // pin: false,
                // markers: true,
                scrub: 0.2,
                start: "top top",
                end: "bottom center"
              }
            }
          )
        : gsap.fromTo(
            ".sales-card:not(:first-child)",
            {
              x: 200,
              opacity: 0
            },
            {
              x: 0,
              opacity: 1,
              stagger: 0.5,
              scrollTrigger: {
                trigger: ref.current,
                pin: true,
                // markers: true,
                scrub: 3,
                start: "top top",
                end: "bottom center",
                invalidateOnRefresh: true
              }
            }
          )
    }, ref)

    return () => ctx.revert()
  }, [isMobile])

  useEffect(() => {
    let ctx = gsap.context(() => {
      isMobile
        ? gsap.fromTo(
            ".roadMapPart:not(:first-child)",
            {
              y: -200,
              x: 0,
              opacity: 0
            },
            {
              y: 0,
              x: 0,
              opacity: 1,
              stagger: 0.5,
              scrollTrigger: {
                trigger: whatsNextRef.current,
                // pin: false,
                // markers: true,
                scrub: 0.2,
                start: "top top",
                end: "bottom center"
              }
            }
          )
        : gsap.fromTo(
            ".roadMapPart:not(:first-child)",
            {
              x: -200,
              opacity: 0
            },
            {
              x: 0,
              opacity: 1,
              stagger: 0.5,
              scrollTrigger: {
                trigger: whatsNextRef.current,
                pin: true,
                // markers: true,
                scrub: 3,
                start: "top top",
                end: "bottom center",
                invalidateOnRefresh: true
              }
            }
          )
    }, whatsNextRef)

    return () => ctx.revert()
  }, [isMobile])

  return (
    <div className="sales">
      <section className="sales-intro">
        <Particles
          id="sales-intro-particles"
          className="particles-here"
          init={particlesInit}
          options={purpleDots}
        />
        <div className="container custom-section">
          <div className="sales-intro__wrapper">
            <OutlinedTitle color={"var(--clr-accent)"}>
              "Sales are the soul of the company."
            </OutlinedTitle>
            <div className="sales-intro__header">
              <h4
                className="text-wrapper"
                style={{
                  fontWeight: 900,
                  textTransform: "uppercase",
                  letterSpacing: "0.2rem"
                }}
              >
                SALES DEPARTMENT IS THE CORE OF EVERY SUCCESSFUL BUSINESS AND
                THE ONLY PART OF A COMPANY THAT ACQUIRES CASH FLOW.
              </h4>
            </div>
            <SalesIntro />
          </div>
        </div>
      </section>
      <section id="sales-cards" className="sales-cards" ref={ref}>
        <div className="container">
          <div className="sales-cards__wrapper">
            <SalesCards
              style={{
                position: "relative",
                top: isMobile ? "0" : "50px",
                backgroundImage: `url(${images.firstSaleCard})`
              }}
              order="first"
              type="accent-card"
              cardText="We can't be of help to everyone, so I want to ask you some questions. If the answer to each of these problems is NO, we won't be of any use to you:"
            />

            <SalesCards
              style={{
                position: "relative",
                backgroundImage: `url(${images.primarySaleCard})`
              }}
              type="primary-card"
              cardText="Are you in the first stage of your business when you want to push your product/services on the market more than anything, but this is very challenging because your opponent is 5-10 years on the market? - - with no doubt"
            />

            <SalesCards
              style={{
                position: "relative",
                top: isMobile ? "0" : "-35px",
                backgroundImage: `url(${images.primarySaleCard})`
              }}
              type="primary-card"
              cardText="Are you convinced that your business will be in a favourable financial situation at some point? However, your company is currently in a phase where you do not have millions of funds to invest in the development and  sales trainers to teach your team how to sell more effectively."
            />

            <SalesCards
              style={{
                position: "relative",
                top: isMobile ? "0" : "23px",
                backgroundImage: `url(${images.primarySaleCard})`
              }}
              type="primary-card"
              cardText="Do you sometimes feel that customers look at you the same as the competition, even though you and the people who work in your company are aware of your virtues, qualities and uniqueness compared to the competition? "
            />

            <SalesCards
              style={{
                position: "relative",
                top: isMobile ? "0" : "-20px",
                backgroundImage: `url(${images.primarySaleCard})`
              }}
              type="primary-card"
              cardText="You are satisfied with your current representatives, but you think that in your market, with the quality of your product/service, the results should be far more positive; that is, your product has the potential for sales to flow much better."
            />

            <SalesCards
              style={{
                position: "relative",
                top: isMobile ? "0" : "30px",
                backgroundImage: `url(${images.lastSaleCard})`
              }}
              order="last"
              textTransform="uppercase"
              type="accent-card"
              cardText="If at least 1/4 of the stated statement applies to you, then you are in the right place!"
            />
            {/* </motion.div> */}
          </div>
        </div>
      </section>
      <motion.div
        transition={{
          type: "spring"
        }}
        animate={{
          y: pageOffset + "%"
        }}
        style={{ position: "relative", opacity, zIndex: -1 }}
        ref={paralaxRef}
      >
        <Particles
          id="sales-second-particles"
          className="particles-here"
          init={particlesInit}
          options={purpleDots}
        />

        <section className="sales-problems">
          <div className="container">
            <div className="custom-section">
              <div className="sales-problems__wrapper">
                <div className="sales-problems__header">
                  <div className="sales-problems-header__intro text-wrapper">
                    <div>
                      <p className="">
                        <motion.span
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ ease: "easeIn", duration: 1 }}
                          className="intensified primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Problem Solver
                        </motion.span>{" "}
                        implements an entirely different way of selling. With
                        our approach, the sale of your services/products will
                        scale and distinguish from what your competition does,
                        no doubt! We encourage companies with sales problems to
                        have 20-150 new deals in the next six months and to
                        bring enough capital into the company without abnormal
                        figures.
                      </p>
                    </div>
                  </div>
                  <h4 className="primary" style={{ letterSpacing: "0.2rem" }}>
                    BUT HOW DO YOU KNOW THAT YOU HAVE A PROBLEM WITH THE SALE?
                  </h4>
                </div>
                <div className="sales-problems__examples">
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeIn", duration: 0.7 }}
                  >
                    <div className="sales-problems__example">
                      <img src={images.salesExample1} alt="" />
                      <p>
                        Are discounts your main strategy for getting more sales?
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeIn", duration: 0.7 }}
                  >
                    <div className="sales-problems__example">
                      <img src={images.salesExample2} alt="" />
                      <p>
                        Are your sales based on quantity and big numbers games?
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeIn", duration: 0.7 }}
                  >
                    <div className="sales-problems__example">
                      <img src={images.salesExample3} alt="" />
                      <p>
                        Do you have a stable income, or every month is
                        different?{" "}
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeIn", duration: 0.7 }}
                  >
                    <div className="sales-problems__example">
                      <img src={images.salesExample4} alt="" />
                      <p>Do you produce more than you can sell?</p>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeIn", duration: 0.7 }}
                  >
                    <div className="sales-problems__example">
                      <img src={images.salesExample5} alt="" />
                      <p>
                        Do customers lie to you, saying that they need to think
                        things over and keep putting off their purchases?
                      </p>
                    </div>
                  </motion.div>
                </div>
                <div className="sales-problems__conclusion">
                  <h4>THEN YOU HAVE A SALES PROBLEM!</h4>
                  <Button
                    className="primary-btn"
                    link="https://calendly.com/problem-solver-/strategy-call"
                  >
                    BOOK A CALL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
      <motion.div
        style={{
          scaleX,
          // height: "0px",
          backgroundColor: "var(--clr-accent)",
          height: "4px",
          right: "0",
          left: "0",
          position: "relative",
          bottom: 0,
          zIndex: 5
        }}
      ></motion.div>
      <section id="sales-philosophy">
        <div className="container">
          <div className="split">
            <div className="split__body">
              <p>
                <span style={{ fontWeight: 700, fontStyle: "normal" }}>
                  Our philosophy
                </span>{" "}
                is that a salesperson who truly believes in his abilities and
                style would much rather get paid on a performance basis than by
                the hour or time he spends on the job. This is why cooperation
                with us is focused on commission.<br></br>
                <br></br>{" "}
              </p>
              <p
                className="accent | intensified "
                style={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  letterSpacing: "0.1rem"
                }}
              >
                Our approach is different and with more promising results.
              </p>
              <br></br>
            </div>
            <div>
              <img src={images.logoBuilding} alt="" />
            </div>
          </div>
          <div className="text-wrapper" style={{ marginBottom: "50px" }}>
            <p style={{ marginBottom: "20px" }}> Think about:</p>
            <div className="sales-mini-cards-wrapper">
              <motion.div
                className="sales-mini-card"
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "easeIn", duration: 0.5 }}
              >
                <p>
                  {" "}
                  How often has someone called you to sell you something out of
                  their interest, not knowing anything about your business, and
                  at the same time convincing you that it is good for you?
                </p>
              </motion.div>
              <motion.div
                className="sales-mini-card"
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "easeIn", duration: 0.5 }}
              >
                <p>
                  How many times have you had someone call you and then just
                  started talking about the product and bombarded you with the
                  technicalities of the product?
                </p>
              </motion.div>
              <motion.div
                className="sales-mini-card"
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ ease: "easeIn", duration: 0.5 }}
              >
                <p>
                  What do you think of people who send you 12 follow-up emails
                  with the explicit goal of selling you their service?
                </p>
              </motion.div>
            </div>
          </div>
          <div className="title">
            <motion.div
              initial={{ opacity: 0.5, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ ease: "easeIn", duration: 0.3 }}
            >
              <h3>
                DO YOU THINK THAT THIS SALE STYLE IS{" "}
                <span className="accent">PROFITABLE</span> AND{" "}
                <span className="accent">PRACTICAL</span>?
              </h3>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="solution">
        <div className="container">
          <div className="solution__wrapper">
            <div className="solution__examples">
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ease: "easeIn", duration: 0.7 }}
                className="solution__example"
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <img src={salesSolutionExample1} alt="" />
                </div>
                <p>
                  It's proven that people don't make a purchase based on the
                  product/service itself but because of the way you talk to
                  them. Because of that, we will represent your service in a way
                  which is different from your opponent and will affect that
                  your customer sees that you are unique.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ease: "easeIn", duration: 0.7 }}
                className="solution__example"
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <img src={salesSolutionExample2} alt="" />
                </div>
                <p>
                  Our team mastered the assessment of human behaviour, finding
                  your customer's pain points and the best ways to approach
                  them. They specialize in estimating different types of your
                  customers and have over five years of experience studying
                  consumer psychology.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ease: "easeIn", duration: 0.7 }}
                className="solution__example"
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <img src={salesSolutionExample3} alt="" />
                </div>
                <p>
                  We will study your product/service for 10 days, create the
                  best way to market it, and approach customers.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="whats-next" ref={whatsNextRef}>
        <div className="container div whats-next__wrapper">
          <div className="whats-next__header">
            <OutlinedTitle tag="h2" color={`var(--clr-accent)`}>
              WHAT'S NEXT?
            </OutlinedTitle>
          </div>
          <div className="roadmap">
            <div className="roadMapPart">
              <img
                src={!isMobile ? salesProcess1 : salesProcessVertical1}
                alt=""
              />
            </div>
            <div
              className="roadMapPart roadmapPart--primary"
              style={{ left: "-4rem" }}
            >
              <img
                src={!isMobile ? salesProcess2 : salesProcessVertical2}
                alt=""
              />
            </div>
            <div
              className="roadMapPart"
              style={{
                position: "relative",
                left: !isMobile ? "-6.3rem" : "-1rem"
              }}
            >
              <img
                src={!isMobile ? salesProcess3 : salesProcessVertical3}
                alt=""
              />
            </div>
            <div
              className="roadMapPart roadmapPart--primary"
              style={{ left: "-8.5rem" }}
            >
              <img
                src={!isMobile ? salesProcess4 : salesProcessVertical4}
                alt=""
              />
            </div>
            <div
              className="roadMapPart"
              style={{
                position: "relative",
                left: !isMobile ? "-10rem" : "-1rem"
              }}
            >
              <img
                src={!isMobile ? salesProcess5 : salesProcessVertical5}
                alt=""
              />
            </div>
            <div
              className="roadMapPart roadmapPart--primary"
              style={{ left: "-11.8rem" }}
            >
              <img
                src={!isMobile ? salesProcess6 : salesProcessVertical6}
                alt=""
              />
            </div>
            <div
              className="roadMapPart"
              style={{
                position: "relative",
                left: !isMobile ? "-15rem" : "-1rem"
              }}
            >
              <img
                src={!isMobile ? salesProcess7 : salesProcessVertical7}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="sales-end">
        <div className="container">
          <div className="custom-section">
            <div className="sales-end__wrapper">
              <div className="sales-end__header">
                <p className="intensified " style={{ fontWeight: 500 }}>
                  <span
                    style={{
                      fontWeight: 700,
                      fontStyle: "normal",
                      letterSpacing: "0.1rem"
                    }}
                  >
                    DEAR READER,
                  </span>
                  <br></br>
                  <br></br> I believe that this text peaked your interest since
                  you reached the end. <br></br> Ask yourself again before you
                  do anything:
                </p>
              </div>
              <div className="sales-end__questions">
                <div
                  className="sales-end__question"
                  style={{
                    backgroundImage: `url(${salesEnd1})`,
                    backgroundPosition: "center"
                  }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      fontWeight: 500
                    }}
                  >
                    Do I have anything to<br></br> lose from this deal?
                  </h5>
                </div>
                <div
                  className="sales-end__question"
                  style={{
                    backgroundImage: `url(${salesEnd2})`,
                    backgroundPosition: "right"
                  }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      fontWeight: 500
                    }}
                  >
                    IS IT A PROFITABLE OPTION FROM A FINANCIAL POINT OF VIEW?
                  </h5>
                </div>
              </div>
              <div className="sales-ending">
                <h4
                  className="accent"
                  style={{
                    fontWeight: 500,
                    textTransform: "uppercase"
                  }}
                >
                  Problem Solver helps you bring more money into the company,
                  without having to invest your time and focus on hiring and
                  educating your team. Let's make your business grow!
                  {/* You bring
                  more money into the company without spending time and focus on
                  hiring, fixed salary and education for your team. */}
                  {/* THIS TIME IF THE ANSWER EVERYTHING IS NOT "YES", I DON'T THINK
                  THAT WE ARE A GOOD FIT, BUT IF YOU AGREE WITH BOTH STATEMENTS: */}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer link="https://calendly.com/problem-solver-/strategy-call" />
    </div>
  )
}

export default Sales
