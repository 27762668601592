import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import { useState, useEffect } from "react"
// import problemSolverVideoMp4 from "./images/Solver.mp4"
// import problemSolverVideoWebm from "./images/Solver.webm"

import AnimatedRoutes from "./components/AnimatedRoutes"
import ProgressPage from "./components/ProgressPage/ProgressPage"
import { useRef } from "react"
import { PreloadContextProvider } from "./context/PreloadContext"

let isVideoPlayed = sessionStorage.getItem("videoPlayed")
// let isVideoPlayed = false

function App() {
  const [loading, setLoading] = useState(isVideoPlayed ? true : false)
  const [preloadImages, setPreloadImages] = useState(
    isVideoPlayed ? true : false
  )
  const [playVideo, setPlayVideo] = useState(isVideoPlayed ? "" : "show")
  const [videoLoaded, setVideoLoaded] = useState(false)

  const videoRef = useRef()

  function closeVideo() {
    setPlayVideo("revert")
    setTimeout(() => {
      setPlayVideo("")
    }, 300)
  }

  function handleVideoLoaded() {
    console.log("handle load", videoRef.current.readyState)
    if (videoRef.current.readyState >= 3) {
      console.log("handle load success")
      videoRef.current.play()
      setVideoLoaded(true)
      sessionStorage.setItem("videoPlayed", "true")
    }
  }

  function endVideo() {
    console.log("end video")
    sessionStorage.setItem("videoPlayed", "true")
    document.body.style.overflowY = "auto"
    document.body.style.paddingRight = "0"
    closeVideo()
    // setPreloadImages(true)
  }

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 6000)
    }
  }, [loading])

  useEffect(() => {
    if (playVideo && !isVideoPlayed && videoRef.current) {
      document.body.style.overflowY = "hidden"
      document.body.style.paddingRight = "17px"

      videoRef.current.defaultMuted = true
      videoRef.current.muted = true

      setTimeout(() => {
        if (!playVideo || videoLoaded || !videoRef.current) {
          return
        }

        console.log(
          "13s timeout",
          videoRef.current.currentTime,
          videoRef.current.paused,
          videoRef.current.ended,
          videoRef.current.readyState
        )

        handleVideoLoaded()
        if (
          videoRef.current.currentTime == 0 ||
          videoRef.current.paused ||
          videoRef.current.ended ||
          videoRef.current.readyState < 3
        ) {
          endVideo()
        }
      }, 13000)
      setTimeout(() => {
        if (!playVideo || videoLoaded || !videoRef.current) {
          return
        }
        console.log(
          "20s timeout",
          videoRef.current.currentTime,
          videoRef.current.paused,
          videoRef.current.ended,
          videoRef.current.readyState
        )

        handleVideoLoaded()

        if (
          videoRef.current.currentTime == 0 ||
          videoRef.current.paused ||
          videoRef.current.ended ||
          videoRef.current.readyState < 3
        ) {
          endVideo()
        }
      }, 20000)
    }
  }, [playVideo])

  useEffect(() => {
    setLoading(true)
  }, [])

  return (
    <>
      <ProgressPage loading={loading} />
      <div className={`loadingVideoWrapper ${playVideo}`}>
        {playVideo == "show" ? (
          <video
            ref={videoRef}
            muted
            autoPlay
            width="100%"
            controls={false}
            playsInline
            onLoadedData={() => handleVideoLoaded()}
            onCanPlayThrough={() => handleVideoLoaded()}
            onEnded={() => endVideo()}
          >
            <source
              src={
                "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/Solver.mp4"
              }
              type="video/mp4"
            />
            <source
              src={
                "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/Solver.webm"
              }
              type="video/webm"
            />
          </video>
        ) : (
          ""
        )}

        <div className={`play-video-wrapper ${!videoLoaded ? "active" : ""}`}>
          <p>Please wait...</p>
        </div>
      </div>

      <PreloadContextProvider ready={preloadImages}>
        <Router>
          <AnimatedRoutes />
        </Router>
      </PreloadContextProvider>
    </>
  )
}

export default App
