import { motion } from "framer-motion"
import React from "react"

import Button from "../../../components/Button/Button"
import Xarrow from "react-xarrows"
import { useMobileContext } from "../../../context/MobileContext"
import { usePreloadContext } from "../../../context/PreloadContext"
// import LineTo from "react-lineto"

const SalesIntro = () => {
  const isMobile = useMobileContext()
  const { images } = usePreloadContext()

  return (
    <>
      <div className="sales-intro__body">
        <div className="sales-example__wrapper">
          <div id="1" className="sales-intro__example 1 sales-example-1">
            <div style={{ position: "relative" }}>
              <div className="sales-example__icon">
                <img src={images.salesIcon1} alt="" id="first" />
              </div>
              {/* <div className="marker__dot"></div> */}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileTap={{ scale: 0.8 }}
              whileInView={{ opacity: 1 }}
              transition={{ ease: "easeIn", duration: 0.5 }}
              className="sales-intro__example_text"
            >
              <p className=" ">
                Good sales are crucial to your business's development; without
                them, there are no results. Implementing a quality sales team at
                the beginning of your company's development is complex. Big
                fixed salaries, registration and investing in different sales
                trainers to train your team is something that only comes when
                the business develops.
              </p>
            </motion.div>
          </div>
        </div>

        <div className="sales-example__wrapper">
          <div className="sales-intro__example 2 sales-example-2" id="2">
            <div style={{ position: "relative" }}>
              <div className="sales-example__icon">
                <img src={images.salesIcon2} alt="" id="second" />
              </div>
              {/* <div className="marker__dot"></div> */}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileTap={{ scale: 0.8 }}
              whileInView={{ opacity: 1 }}
              transition={{ ease: "easeIn", duration: 0.5 }}
              className="sales-intro__example_text"
            >
              <h3
                className="accent"
                style={{
                  fontWeight: 800,
                  letterSpacing: "0.1rem",
                  textTransform: "uppercase"
                }}
              >
                But how to scale your business without a good sales team?
              </h3>
              <p className=" ">
                <br></br>
                What if I told you that we can provide trained sales
                representatives to sell your products/services for you and
                generste additional i come for your company without initial
                cost?
              </p>
            </motion.div>
          </div>
        </div>

        {!isMobile && (
          <Xarrow
            start={"first"}
            end={"second"}
            showHead={false}
            color={"#7F51BF"}
            curveness={0}
            _cpx1Offset={400}
            _cpx2Offset={0}
            startAnchor="right"
            endAnchor="top"
          />
        )}
        <div className="sales-example__wrapper">
          <div className="sales-intro__example sales-example-3">
            <div style={{ position: "relative" }}>
              <div className="sales-example__icon">
                <img src={images.salesIcon3} alt="" id="third" />
              </div>
              {/* <div className="marker__dot"></div> */}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileTap={{ scale: 0.8 }}
              whileInView={{ opacity: 1 }}
              transition={{ ease: "easeIn", duration: 0.5 }}
              className="sales-intro__example_text"
            >
              <h3
                className="accent"
                style={{
                  fontWeight: 800,
                  letterSpacing: "0.1rem",
                  textTransform: "uppercase"
                }}
              >
                That's right, that's what we do!
              </h3>
              <p className=" ">
                <br></br> We can sell your products/services and bring
                additional income to the company that you can use for further
                development. You don't have to invest or spend money on training
                people and fixed salaries! You pay us a commission only after we
                sell your product/service!
              </p>
            </motion.div>
          </div>
        </div>
        {!isMobile && (
          <Xarrow
            start={"second"}
            end={"third"}
            showHead={false}
            color={"#7F51BF"}
            curveness={0}
            _cpx1Offset={-400}
            _cpx2Offset={0}
            startAnchor="left"
            endAnchor="top"
          />
        )}
        <Button
          className="accent-btn | pulse"
          link="https://calendly.com/problem-solver-/strategy-call"
          id="fourth"
        >
          BOOK A CALL
        </Button>
        {!isMobile && (
          <Xarrow
            start={"third"}
            end={"fourth"}
            showHead={false}
            color={"#7F51BF"}
            curveness={0}
            _cpx1Offset={200}
            _cpx2Offset={0}
            startAnchor="right"
            endAnchor="top"
          />
        )}
      </div>
    </>
  )
}

export default SalesIntro
