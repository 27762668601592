import React, { useEffect, useRef } from "react"

// import placeholder2 from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/handshake.jpg"
// import process from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/process.png"
// import processShadow from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/process-shadow.png"
// import arrowDown from "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/arrow-down.png"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { motion } from "framer-motion"
import Particles from "react-tsparticles"
import { particlesInit, whiteLines } from "../../assets/particlesConfig"

import "./investitor.css"
import Footer from "../../components/Footer/Footer"
import { useMobileContext } from "../../context/MobileContext"
import Button from "../../components/Button/Button"
import OutlinedTitle from "../../components/OutlinedTitle/OutlinedTitle"
import VideoWrapper from "../../components/VideoWrapper/VideoWrapper"
import { useState } from "react"
import { usePreloadContext } from "../../context/PreloadContext"
gsap.registerPlugin(ScrollTrigger)

function Investitor() {
  const isMobile = useMobileContext()
  const { images } = usePreloadContext()
  const [isFirstHovered, setIsFirstHovered] = useState(false)
  const [isSecondHovered, setIsSecondHovered] = useState(false)
  const [openVideo, setOpenVideo] = useState(false)
  const [isVideoPlayed, setIsVideoPlayed] = useState(false)
  const [isThirdHovered, setIsThirdHovered] = useState(false)
  const ref = useRef(null)

  function endVideo() {
    setIsVideoPlayed(true)
  }
  function handleClose() {
    setOpenVideo(false)
  }
  const headerVariants = {
    rest: {
      y: 100,
      scale: 1.5,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 100,
      scale: 1.2,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [100, 60, 100],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  const textVariants = {
    rest: {
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [100, 60, 100],
      opacity: [0, 1, 0],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  const animationVariants = {
    initial: () =>
      isMobile
        ? {
            x: -200,
            y: 0,
            opacity: 0
          }
        : {
            x: 0,
            y: -100,
            opacity: 0
          },
    active: () => ({
      y: 0,
      x: 0,
      opacity: 1
    })
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        "#process__circle",
        {
          rotation: 0
        },
        {
          rotation: -360,
          scrollTrigger: {
            trigger: ref.current,
            pin: true,
            // markers: true,
            scrub: 5,
            start: "center center",
            end: `top+=${ref.current.scrollHeight}`
          }
        }
      )
    }, ref)
    return () => ctx.revert()
  }, [])

  return (
    <>
      <div className="investitor">
        <section className="intro">
          <Particles
            className="particles-here"
            init={particlesInit}
            options={whiteLines}
          />
          <div className="container">
            <div className="intro__wrapper">
              <div className="intro__header">
                <OutlinedTitle>INVESTOR</OutlinedTitle>
                <motion.div
                  className={`play-video-button ${
                    isVideoPlayed ? "" : "pulse"
                  }`}
                  onClick={() => setOpenVideo(true)}
                >
                  <i className="fa-solid fa-play"></i>
                </motion.div>
                <h4 className="text-wrapper" style={{ fontWeight: 700 }}>
                  You can start business in different ways:
                </h4>
              </div>
              <div className="intro__cards">
                <motion.div
                  variants={animationVariants}
                  initial={"initial"}
                  whileInView={"active"}
                  transition={{ duration: 0.8 }}
                >
                  <div className="intro__card | card-1">
                    <div
                      className="card__img"
                      style={{
                        backgroundImage: `url(${images.investorCard1}), linear-gradient(
                          358.77deg,
                          #100617 0.91%,
                          rgba(16, 6, 23, 0.644705) 37.77%,
                          rgba(16, 6, 23, 0) 98.83%
                        )`
                      }}
                    ></div>
                    <div className="card__text">
                      <h4 style={{ fontWeight: 500 }}>
                        WORK WITHOUT INITIAL CAPITAL
                      </h4>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  variants={animationVariants}
                  initial={"initial"}
                  whileInView={"active"}
                  transition={{ duration: 0.8 }}
                >
                  <div className="intro__card | card-2">
                    <div
                      className="card__img"
                      style={{
                        backgroundImage: `url(${images.investorCard2}), linear-gradient(
                          358.77deg,
                          #100617 0.91%,
                          rgba(16, 6, 23, 0.644705) 37.77%,
                          rgba(16, 6, 23, 0) 98.83%
                        )`
                      }}
                    ></div>
                    <div className="card__text">
                      <h4 style={{ fontWeight: 500 }}>
                        BORROW MONEY THAT YOU INVEST IN THE DEVELOPMENT OF YOUR
                        COMPANY
                      </h4>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  variants={animationVariants}
                  initial={"initial"}
                  whileInView={"active"}
                  transition={{ duration: 0.8 }}
                >
                  <div className="intro__card | card-3">
                    <div
                      className="card__img"
                      style={{
                        backgroundImage: `url(${images.investorCard3}), linear-gradient(
                            358.77deg,
                            #100617 0.91%,
                            rgba(16, 6, 23, 0.644705) 37.77%,
                            rgba(16, 6, 23, 0) 98.83%
                          )`
                      }}
                    ></div>
                    <div className="card__text">
                      <h4 style={{ fontWeight: 500 }}>
                        INVEST THE WEALTH YOU HAVE ACCUMULATED OVER THE YEARS
                      </h4>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="text-section" style={{ paddingBottom: "0" }}>
              <div
                className="intro__body text-wrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <p>
                  These methods also give results and you can grow your company
                  with them. They can take you to the desired place if you are
                  ready for a lot of effort, risk and loans. However, I suggest
                  a fourth way.
                </p>
                <motion.div
                  animate={{
                    translateY: ["0", "5px", "-5px"],
                    opacity: [0.9, 1, 0.9]
                  }}
                  transition={{
                    duration: 1,
                    ease: "linear",
                    repeat: Infinity
                  }}
                  style={{ width: "7%" }}
                >
                  <img
                    src={
                      "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/arrow-down.png"
                    }
                    alt="arrow-down"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="cta"
          style={{ backgroundImage: `url(${images.investorAngel})` }}
        >
          <div className="container">
            <div className="cta__body text-wrapper">
              <p className="intensified">
                Find an investor who will provide you with all the necessary
                funds and invest in the development of your business.
              </p>
            </div>
          </div>
        </div>
        <section className="logo-section">
          {/* <Particles
            id="investor-logo-section-particles"
            className="particles-here"
            init={particlesInit}
            options={purpleDots}
          /> */}
          <div className="container">
            <div className="logo-section__body text-wrapper">
              <div>
                <p>
                  However, finding an adequate investor is not an easy task. But
                  what if I told you there is a firm that could quickly and
                  effortlessly connect you with precisely the investor you want
                  to attract?
                </p>
              </div>
              <div className="logo-section__important">
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  whileInView={{ opacity: 1, scale: [0.9, 1.1, 1] }}
                  transition={{ ease: "easeIn", duration: 0.5 }}
                >
                  <img src={images.logo} alt="problemSolver"></img>
                </motion.div>
                <div>
                  <p style={{}}>
                    We are a company that supports ambitious, creative,
                    innovative entrepreneurs with a business development plan,
                    people, and a ready project, but without enough money for
                    its implementation.<br></br>
                    <br></br>Whether you need 20k, 50k, 100k, a milion or a 100,
                    leave that to us.
                  </p>
                  <Button
                    className="primary-btn"
                    style={{ marginTop: "2rem " }}
                    link="https://calendly.com/problem-solver-/strategy-call"
                  >
                    BOOK A CALL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="problems">
          <div className="container">
            <div className="problems__wrapper">
              <div className="problems__header">
                <motion.div
                  initial={{ opacity: 0.5, scale: 0.7 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                >
                  <h3>
                    <span className="primary">IMPORTANCE OF INVESTORS</span> FOR
                    THE COMPANY
                  </h3>
                </motion.div>
              </div>
              <div className="problems__body text-wrapper">
                <p>
                  Suppose you are a small or medium-sized business owner and
                  intend to improve your business in the coming years,
                  accelerate the company's growth and enter a new and more
                  extensive market. In that case, you must know the role of
                  investors in that process and how important they are for those
                  goals.<br></br>
                  <br></br> Starting a business is challenging. The difference
                  between you and competitors who have been on the market for
                  more than ten years is enormous, and we all know that
                  well-placed people with capital trample new companies and
                  hinder their development.<br></br>
                  <br></br>
                  <span className="primary">
                    These are just some of the big problems that small and
                    medium-sized businesses that do not have investment support
                    are facing:
                  </span>
                </p>
              </div>
              <div className="problems__examples">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                  style={{ flex: 1 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon1} alt="" />
                    </div>
                    <p>Financial instability</p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                  style={{ flex: 1 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon2} alt="" />
                    </div>
                    <p>Additional borrowings</p>
                  </div>
                </motion.div>
                <motion.div
                  style={{ flex: 1 }}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon3} alt="" />
                    </div>
                    <p>A constant struggle to get out of debt and break even</p>
                  </div>
                </motion.div>
                <motion.div
                  style={{ flex: 1 }}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon4} alt="" />
                    </div>
                    <p>Lack of license and uncertainty in work</p>
                  </div>
                </motion.div>
                <motion.div
                  style={{ flex: 1 }}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon5} alt="" />
                    </div>
                    <p>Lack of focus on business development</p>
                  </div>
                </motion.div>
                <motion.div
                  style={{ flex: 1 }}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeIn", duration: 0.7 }}
                >
                  <div className="example">
                    <div className="example__icon">
                      <img src={images.investorIcon6} alt="" />
                    </div>
                    <p>
                      Finding the right investor by yourself is a difficult and
                      risky job
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="cta"
          style={{
            paddingBlock: "5.5rem",
            backgroundImage: `url(${images.placeholder1})`,
            backgroundColor: "rgb(0,0,0,0.5)",
            backgroundBlendMode: "multiply"
            // filter: `grayscale(40%) brightness(50%)`,
          }}
        >
          <div className="container">
            <div className="text-wrapper">
              <div>
                <p>
                  Would your chances be better if you had substantial start-up
                  capital that would allow you to overcome the barriers in the
                  initial stage of development and efficiently break through the
                  first stage?<br></br>
                </p>
              </div>
              <div>
                <p>
                  <br></br> Partnering with investors allows you to strengthen
                  your company's financial capacity, which is especially
                  important in the stages of development when you still do not
                  have substantial enough capital. Investors represent excellent
                  support for small and medium-sized businesses because they
                  invest their resources and energy in your product, help you
                  achieve financial stability and move towards greater
                  prosperity, and free you from the previous debt.<br></br>
                </p>
              </div>
              <p>
                <br></br> That's why the importance of investors for growing
                businesses is of immeasurable significance - choosing the right
                investor allows you faster, more efficient and more certain
                development and the opportunity to stand side by side with big
                businesses in a brief period.
              </p>
            </div>
          </div>
        </section>
        <section className="why-us" style={{ position: "relative" }}>
          <div className="container">
            <div className="why-us__wrapper">
              <div className="why-us__header">
                <OutlinedTitle color={"var(--clr-accent)"}>
                  WHY US?
                </OutlinedTitle>
                <div className="why-us_questions">
                  <motion.div
                    // variants={animationVariants}
                    // initial={"initial"}
                    // whileInView={"active"}
                    // transition={{ duration: 0.8 }}
                    initial={isMobile ? "restMobile" : "rest"}
                    whileHover="hover"
                    whileInView={isMobile ? "" : "inView"}
                    onClick={() => setIsFirstHovered(!isFirstHovered)}
                    animate={
                      isMobile
                        ? isFirstHovered
                          ? "hover"
                          : "restMobile"
                        : "rest"
                    }
                    className="question-wrapper"
                  >
                    <motion.h3 variants={headerVariants}>1.</motion.h3>
                    <motion.p className=" " variants={textVariants}>
                      Do you consider yourself an expert in your job?
                    </motion.p>
                  </motion.div>
                  <motion.div
                    // variants={animationVariants}
                    // initial={"initial"}
                    // whileInView={"active"}
                    // transition={{ duration: 0.8 }}
                    initial={isMobile ? "restMobile" : "rest"}
                    whileHover="hover"
                    whileInView={isMobile ? "" : "inView"}
                    onClick={() => setIsSecondHovered(!isSecondHovered)}
                    animate={
                      isMobile
                        ? isSecondHovered
                          ? "hover"
                          : "restMobile"
                        : "rest"
                    }
                    className="question-wrapper"
                  >
                    <motion.h3 variants={headerVariants}>2.</motion.h3>
                    <motion.p className=" " variants={textVariants}>
                      Have you analysed your moves and the topic you are dealing
                      with that you know well?
                    </motion.p>
                  </motion.div>
                  <motion.div
                    // variants={animationVariants}
                    // initial={"initial"}
                    // whileInView={"active"}
                    // transition={{ duration: 0.8 }}
                    initial={isMobile ? "restMobile" : "rest"}
                    whileHover="hover"
                    whileInView={isMobile ? "" : "inView"}
                    onClick={() => setIsThirdHovered(!isThirdHovered)}
                    animate={
                      isMobile
                        ? isThirdHovered
                          ? "hover"
                          : "restMobile"
                        : "rest"
                    }
                    className="question-wrapper"
                  >
                    <motion.h3 variants={headerVariants}>3.</motion.h3>
                    <motion.p className=" " variants={textVariants}>
                      Do you know what your customers think and what is most
                      vital for them to buy?
                    </motion.p>
                  </motion.div>
                </div>
                <p
                  className="text-wrapper | intensified "
                  style={{
                    // fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  Probably yes, since you are focused on business development.
                </p>
              </div>
              <div className="why-us__body text-wrapper">
                <div className="why-us__question">
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    className="question-wrapper"
                    style={{ maxWidth: "500px", marginBottom: "5vh" }}
                  >
                    <h3>ONE QUESTION</h3>
                    <p className="">
                      How much do you know about investments, the needs of
                      investors, their preferences, reasonable approach to them,
                      and the way to talk to them?
                    </p>
                  </motion.div>

                  <p style={{ marginBottom: "1rem" }}>
                    How long do you think it will take to find the right person
                    without this knowledge?
                  </p>
                  <p>
                    Probably enough for the idea to be reached by competitors
                    and for someone to overtake you.
                    <br></br>
                    <br></br>
                  </p>
                </div>
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="why-us__answer"
                >
                  <p className=" ">
                    <span
                      className="primary"
                      style={{ fontWeight: 700, fontSize: "1.5rem" }}
                    >
                      Problem Solver
                    </span>{" "}
                    is a company with a network of people with capital ready to
                    invest and support promising and potentially successful
                    projects! So, while you focus on the company's further
                    development and innovation, we will relieve you of the waste
                    of focus and connect you with the person who will help you
                    with as much money as you need. We know where, how and in
                    what way.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="process"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
          ref={ref}
        >
          <div className="process__wrapper">
            <div
              // initial={{ rotate: 0 }}
              // whileInView={{ rotate: "360deg" }}
              // transition={{ ease: "easeIn", duration: 1, times: 2 }}
              className="process__circle"
            >
              <img
                src={
                  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/process-shadow.png"
                }
                alt="Process shadow"
                className="process-shadow"
              />
              <img
                id="process__circle"
                src={
                  "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/process.png"
                }
                style={{ transform: "rotate(30deg)" }}
                alt=""
              />
            </div>
          </div>
        </section>
        <section
          style={{
            paddingBlock: "10rem 10rem",
            backgroundColor: `rgb(16, 6, 23, 0.8)`,
            backgroundBlendMode: "multiply",
            backgroundImage: `url('https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/handshake.jpg')`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center"
          }}
        >
          <div className="container">
            <div className="split">
              <h4
                className="text-wrapper"
                style={{ fontWeight: "700", textAlign: "center" }}
              >
                So, if you want a partner who profoundly understand your
                business and your goals and who can provide exactly as much
                capital as you need while taking care of your entire marketing
                strategy at the same time, your seacrh is over!
              </h4>
            </div>
          </div>
        </section>
        <VideoWrapper
          open={openVideo}
          src={`${process.env.REACT_APP_ASSETS_PATH}/images/PS_Investor.mp4`}
          onVideoEnd={endVideo}
          controls={true}
          exitOption={true}
          handleClose={() => handleClose()}
        />
        <Footer link="https://calendly.com/problem-solver-/strategy-call" />
      </div>
    </>
  )
}

export default Investitor
