import React from "react";
import "./sales-cards.css";

function SalesCards(props) {
  return (
    <div
      style={props.style}
      className={`sales-card  ${props.type} ${props.order}`}
    >
      <p className="card-text" style={{ textTransform: props.textTransform }}>
        {props.cardText}
      </p>
    </div>
  );
}

export default SalesCards;
