import React from "react"
import { useRef } from "react"
import { useThree, useFrame } from "@react-three/fiber"
import { usePreloadContext } from "../context/PreloadContext"

function Model() {
  const { model } = usePreloadContext()

  const ref = useRef()
  const { camera, mouse, size } = useThree()
  let position = size.width < 700 ? [0, 2.5, 0] : [0, 0, 0]
  let scale = size.width < 1088 ? 0.02 : size.width < 1460 ? 0.035 : 0.043

  useFrame(() => ref.current.lookAt(mouse.x, mouse.y, camera.position.z))
  return (
    <primitive object={model} scale={scale} ref={ref} position={position} />
  ) //put scale to 0.02
}

export default Model
