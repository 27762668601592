import "./back-to-top-btn.css"

function BackToTopBtn(props) {
  return (
    <button
      className={`to-top-btn ${props.show ? "show" : ""}`}
      onClick={props.handleClick}
    >
      <div className="to-top-btn-wrapper">
        <i className="fa-solid fa-chevron-right"></i>
        <p>Back to top</p>
      </div>
    </button>
  )
}

export default BackToTopBtn
