import React, { useState } from "react"

import { particlesInit, yellowDots } from "../../assets/particlesConfig"

import { motion } from "framer-motion"

import Particles from "react-tsparticles"
import Footer from "../../components/Footer/Footer"

import "./about.css"
import VideoWrapper from "../../components/VideoWrapper/VideoWrapper"
import OutlinedTitle from "../../components/OutlinedTitle/OutlinedTitle"
import { useMobileContext } from "../../context/MobileContext"
import { useRef } from "react"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"

function About() {
  const location = useLocation()
  const isMobile = useMobileContext()
  const aboutRef = useRef()
  const [openVideo, setOpenVideo] = useState(false)
  const [isVideoPlayed, setIsVideoPlayed] = useState(false)
  const [isFirstHovered, setIsFirstHovered] = useState(false)
  const [isSecondHovered, setIsSecondHovered] = useState(false)

  function endVideo() {
    setIsVideoPlayed(true)
  }
  function handleClose() {
    setOpenVideo(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (location.hash === "#about" && aboutRef.current) {
        aboutRef.current.scrollIntoView({
          behavior: "smooth"
        })
      }
    }, 1000)
  }, [])

  const headerVariants = {
    rest: {
      y: 200,
      scale: 1.5,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 200,
      scale: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [200, 60, 200],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  const textVariants = {
    rest: {
      y: 200,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    restMobile: {
      y: 200,
      opacity: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    },

    inView: {
      y: [200, 60, 200],
      opacity: [0, 1, 0],
      transition: {
        duration: 1,
        type: "tween",
        ease: "easeIn"
      }
    },

    hover: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  }

  return (
    <div ref={aboutRef} id="about" className="about">
      <section className="about-us">
        {/* <Particles
          id="about-particles"
          className="particles-here"
          init={particlesInit}
          options={yellowDots}
        /> */}
        <div className="container">
          <div className="about-us__wrapper">
            <div className="about-us__header">
              <OutlinedTitle>ABOUT US</OutlinedTitle>

              <motion.div
                className={`play-video-button ${isVideoPlayed ? "" : "pulse"}`}
                onClick={() => setOpenVideo(true)}
              >
                <i className="fa-solid fa-play"></i>
              </motion.div>
              <h4 className="text-wrapper" style={{ fontWeight: 700 }}>
                We are a team of individuals with developed skills and
                professionalism, each in their field, who are gathered under the
                same roof with only one goal. Providing value to the client!
              </h4>
            </div>
            <div className="about-us__body">
              <motion.div
                className="about-us__card"
                initial={isMobile ? "restMobile" : "rest"}
                whileHover="hover"
                whileInView={isMobile ? "" : "inView"}
                onClick={() => setIsFirstHovered(!isFirstHovered)}
                animate={
                  isMobile ? (isFirstHovered ? "hover" : "restMobile") : "rest"
                }
              >
                <div style={{ height: "100px", aspectRatio: 1 }}>
                  <img
                    src={
                      "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/hands-up.png"
                    }
                    alt=""
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                    flex: 1
                  }}
                >
                  <motion.h5
                    className="primary"
                    style={{ letterSpacing: "0.2rem" }}
                    variants={headerVariants}
                  >
                    PRIVATE<br></br> CHARACTERISTICS:
                  </motion.h5>
                  <motion.p variants={textVariants}>
                    <br></br>
                    <br></br> Young, ambitious, serious, silly, different,
                    similar, professional, funny, 24/7 focus on growth,
                    enthusiastic, dedicated with a way of thinking best
                    described by Steve Jobs' sentence:<br></br>
                    <br></br>{" "}
                    <span>
                      "Only those crazy enough to think they can change the
                      world are the ones who do."
                    </span>
                  </motion.p>
                </div>
              </motion.div>
              <motion.div
                className="about-us__card"
                initial={isMobile ? "restMobile" : "rest"}
                whileHover="hover"
                whileInView={isMobile ? "" : "inView"}
                onClick={() => setIsSecondHovered(!isSecondHovered)}
                animate={
                  isMobile ? (isSecondHovered ? "hover" : "restMobile") : "rest"
                }
              >
                <div style={{ height: "100px", aspectRatio: 1 }}>
                  <img
                    src={
                      "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/professional-portfolio.png"
                    }
                    alt=""
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                    flex: 1
                  }}
                >
                  <motion.h5
                    className="primary"
                    style={{ letterSpacing: "0.2rem" }}
                    variants={headerVariants}
                  >
                    PROFESSIONALLY:
                  </motion.h5>

                  <motion.p variants={textVariants}>
                    <br></br>
                    <br></br> Our team is carefully selected. In every segment
                    of the company, there are professionals with over five years
                    of experience, hundred projects behind them, and people who
                    bring their heart to every project and are maximally
                    committed to the company's development!
                  </motion.p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="ceo-section">
        <div className="container">
          <div className="custom-section">
            <div className="ceo__wrapper text-wrapper">
              <div className="ceo__header">
                <h3>
                  <span className="accent">WHO ARE WE </span>FROM THE POINT OF
                  VIEW OF OUR CEO?
                </h3>
              </div>
              <div className="ceo__body ">
                <div style={{ textAlign: "center" }}>
                  <div className="ceo__image">
                    <img
                      src={
                        "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/ceo.jpeg"
                      }
                      alt=""
                    />
                  </div>
                  <p className="intensified" style={{ marginTop: "1rem" }}>
                    Dušan Stanarević
                  </p>
                  <p className="" style={{ fontStyle: "italic" }}>
                    CEO & Founder
                  </p>
                </div>
                <div className="ceo__speech">
                  <p>
                    My name is Dušan,
                    <br />
                    <br /> We call ourselves an innovative marketing agency
                    because Problem Solver is a place where an idea that is not
                    even written as a business plan or a company that is already
                    operating can provide the entire infrastructure for its
                    development to full expansion.
                    <br />
                    <br /> We enable inspiring individuals and growing companies
                    with remarkable ideas with as much capital as they need. We
                    allow firms to boost their sales by having our trained
                    professionals sell for them in a commission-based way of
                    collaboration.
                    <br />
                    <br /> We implement a 3D display of their business model for
                    companies that need innovation. This detail will instantly
                    raise them above the competition and increase their sales.
                    Also, they'll have all of the top-quality marketing services
                    in one place!
                    <br />
                    <br /> Our team is the perfect combination of passion,
                    youth, ambition, years of experience, a professional
                    attitude, and expertise.
                    <br />
                    <br /> We work, grow and build your company{" "}
                    <span
                      style={{
                        color: "var(--clr-accent)",
                        fontWeight: 900,
                        fontStyle: "normal",
                        // textDecoration: "underline",
                        textTransform: "uppercase"
                      }}
                      className="intensified"
                    >
                      with
                    </span>{" "}
                    you!
                    <br />
                    <br /> So, the best answer to the question "Who are we" is:
                    Problem Solver is not just a service provider,{" "}
                    <span
                      style={{
                        color: "var(--clr-accent)",
                        fontWeight: 900,
                        fontStyle: "normal",
                        textDecoration: "underline"
                      }}
                      className="intensified"
                    >
                      Problem Solver is a business partner
                    </span>
                    !
                  </p>
                </div>
              </div>
              <div className="ceo__end">
                <h5
                  style={{
                    letterSpacing: "0.2rem",
                    textTransform: "uppercase"
                  }}
                >
                  If you are looking for innovation, creativity, and
                  professionalism, go deeper into our business. We are waiting
                  for you!
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <VideoWrapper
        open={openVideo}
        src={
          "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/ps-campaign.mp4"
        }
        onVideoEnd={endVideo}
        controls={true}
        exitOption={true}
        handleClose={() => handleClose()}
      />

      <Footer link="https://calendly.com/problem-solver-agency/strategy-discovery-call" />
    </div>
  )
}

export default About
