import React from "react"
import "./nav-info.css"

const NavInfo = () => {
  return (
    <div className="nav__info">
      <p>Vojvode Stepe 78, Voždovac, Belgrade</p>
      <p>Registration number: 21797804</p>
      <p>+381 64 1389 265</p>
      <p>office@problemsolveragency.com</p>
    </div>
  )
}

export default NavInfo
