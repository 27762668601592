import { useEffect, useState, createContext, useContext } from "react"
import { useFBX } from "@react-three/drei"

import "react-multi-carousel/lib/styles.css"

// https://cdn.jsdelivr.net/gh/itsoffbrand/totem-prod@latest/totem.glb
// https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marketing-bg.png

// Here you add all large images you want to preload

const images = {
  marketingBg:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marketing-bg.png",
  marker:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marker.png",
  giphy: "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/giphy.gif",
  marker1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marker-1.png",
  marker2:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marker-2.png",
  marker3:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marker-3.png",
  marker4:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/marker-4.png",
  logo: "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/logo.png",
  salesIcon1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-icon-1.png",
  salesIcon2:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-icon-2.png",
  salesIcon3:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-icon-3.png",
  primarySaleCard:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/primary-card-bg.png",
  firstSaleCard:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/rotated-arrow.png",
  lastSaleCard:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/correct.png",
  salesExample1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-1.png",
  salesExample2:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-2.png",
  salesExample3:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-1.png",
  salesExample4:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-4.png",
  salesExample5:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/sales-example-5.png",
  logoBuilding:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/logo-building.png",
  investorAngel:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/cta-bg.png",
  placeholder1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/people-writing.jpg",
  investorCard1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/card-1.png",
  investorCard2:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/card-2.png",
  investorCard3:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/card-3.png",
  investorIcon1:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-1.png",
  investorIcon2:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-2.png",
  investorIcon3:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-3.png",
  investorIcon4:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-4.png",
  investorIcon5:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-5.png",
  investorIcon6:
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/example-icon-6.png"
}

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = img.onabort = function () {
      reject(src)
    }
    img.src = src
  })
}

// HOOK to detect if images object is loaded
export default function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false)

  useEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      var imagesPromiseList = {}
      Object.keys(imageList).forEach((key, index) => {
        imagesPromiseList = {
          ...imagesPromiseList,
          [key]: preloadImage(imageList[key])
        }
      })
      // for (const i of imageList) {
      //   imagesPromiseList.push(preloadImage(i))
      // }

      await Promise.all(Object.values(imagesPromiseList))

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [imageList])

  return { imagesPreloaded }
}

const PreloadContext = createContext()

export const usePreloadContext = () => {
  return useContext(PreloadContext)
}

export const PreloadContextProvider = ({ children, ready }) => {
  const fbx = useFBX(
    "https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/models/PS_Logo.fbx"
  )
  const { imagesPreloaded } = useImagePreloader(ready ? images : false)

  return (
    <PreloadContext.Provider value={{ images: images, model: fbx }}>
      {children}
    </PreloadContext.Provider>
  )
}
